<template>
  <viewer :images="fileList" class="image-view">
    <div class="image-view-item" v-for="item in fileList" :key="item">
      <span @click.stop="handlePdfPreview(item)" v-if="item && item.indexOf('.pdf') > 0">PDF文档</span>
      <span @click.stop="handleOfficePreview(item)" v-else-if="item && (item.indexOf('.xls') > 0 || item.indexOf('.xlsx') > 0)">Excel文档</span>
      <img v-else :src="item" height="100" />
    </div>
  </viewer>
</template>
<script>
export default {
  name: 'ImageView',
  props: {
    imageUrl: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  mounted() {
    this.setFileList(this.imageUrl)
  },
  watch: {
    imageUrl(newVal, oldVal) {
      this.setFileList(newVal)
    }
  },
  methods: {
    setFileList(d) {
      this.fileList = []
      if (!d) {
        return
      }
      if (Array.isArray(d)) {
        d.forEach((p) => {
          this.fileList.push(p)
        })
      }
    },
    handlePdfPreview(url) {
      var iWidth = 800 // 弹出窗口的宽度;
      var iHeight = 600 // 弹出窗口的高度;
      var iTop = (window.screen.height - 30 - iHeight) / 2 // 获得窗口的垂直位置;
      var iLeft = (window.screen.width - 10 - iWidth) / 2 // 获得窗口的水平位置;
      window.open(
        url,
        '',
        'height=' +
          iHeight +
          ',,innerHeight=' +
          iHeight +
          ',width=' +
          iWidth +
          ',innerWidth=' +
          iWidth +
          ',top=' +
          iTop +
          ',left=' +
          iLeft +
          ',toolbar=no,menubar=no,scrollbars=no,resizeable=no,location=no,status=no'
      )
    },
    handleOfficePreview(url) {
      const newUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(url)
      this.handlePdfPreview(newUrl)
    }
  }
}
</script>
<style lang="less" scoped>
.image-view {
  display: flex;
  flex-wrap: wrap;
  img,
  span {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 8px;
  }
  span {
    display: inline-block;
    text-align: center;
    line-height: 80px;
  }
  .image-view-item {
    display: inline-block;
  }
}
</style>
