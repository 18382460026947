var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      staticClass: "labor-contract-modal",
      attrs: {
        title: _vm.title,
        width: "40%",
        height: "auto",
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        position: { top: "5%", left: "30%" },
      },
      on: { close: _vm.handleCloseModal },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", disabled: _vm.form.isEdit },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _vm.items.length === 0
        ? _c("a-card", { staticClass: "item" }, [_c("a-empty")], 1)
        : _vm._l(_vm.items, function (item, index) {
            return _c("a-card", { key: index, staticClass: "item" }, [
              !item.isEdit
                ? _c("div", { staticClass: "container" }, [
                    _c(
                      "div",
                      { staticClass: "edit-btn" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { disabled: _vm.form.isEdit },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(item)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "a-button",
                          {
                            attrs: { disabled: _vm.form.isEdit },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(item, index)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("span", [_vm._v("签订日期：")]),
                      _c("span", { staticClass: "with-btn" }, [
                        _vm._v(_vm._s(item.signingDate)),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("span", [_vm._v("合同日期：")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(item.contractBeginDate) +
                            " 至 " +
                            _vm._s(item.contractEndDate)
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("span", [_vm._v("工作职务：")]),
                      _c("span", [_vm._v(_vm._s(item.jobTitle))]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("span", [_vm._v("附件：")]),
                        _c("image-view", {
                          attrs: { "image-url": item.enclosureUrl },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("span", [_vm._v("备注：")]),
                      _c("span", [_vm._v(_vm._s(item.remark))]),
                    ]),
                  ])
                : _vm._e(),
              item.isEdit
                ? _c(
                    "div",
                    { staticClass: "container-edit" },
                    [
                      _c(
                        "a-form-model",
                        {
                          ref: "form",
                          refInFor: true,
                          attrs: { model: _vm.form, rules: _vm.rules },
                        },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: { label: "签订日期", prop: "signingDate" },
                            },
                            [
                              _c("a-date-picker", {
                                attrs: {
                                  "value-format": "YYYY-MM-DD",
                                  format: "YYYY-MM-DD",
                                  allowClear: false,
                                },
                                model: {
                                  value: _vm.form.signingDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "signingDate", $$v)
                                  },
                                  expression: "form.signingDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "开始日期",
                                prop: "contractBeginDate",
                              },
                            },
                            [
                              _c("a-date-picker", {
                                attrs: {
                                  "value-format": "YYYY-MM-DD",
                                  format: "YYYY-MM-DD",
                                  allowClear: false,
                                },
                                model: {
                                  value: _vm.form.contractBeginDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractBeginDate", $$v)
                                  },
                                  expression: "form.contractBeginDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "结束日期",
                                prop: "contractEndDate",
                              },
                            },
                            [
                              _c("a-date-picker", {
                                attrs: {
                                  "value-format": "YYYY-MM-DD",
                                  format: "YYYY-MM-DD",
                                  allowClear: false,
                                },
                                model: {
                                  value: _vm.form.contractEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contractEndDate", $$v)
                                  },
                                  expression: "form.contractEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "工作职务", prop: "jobTitle" } },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入工作职务",
                                  allowClear: "",
                                },
                                model: {
                                  value: _vm.form.jobTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "jobTitle", $$v)
                                  },
                                  expression: "form.jobTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "附件上传" } },
                            [
                              _c("many-file-upload", {
                                attrs: {
                                  "init-file-list": _vm.initFileList,
                                  folder: "laborcontract",
                                  type: "image",
                                  maxFileCount: 10,
                                },
                                on: {
                                  onUploading: function ($event) {
                                    _vm.uploading = $event
                                  },
                                  billImageNum: function ($event) {
                                    _vm.form.billImageNum = $event
                                  },
                                  fileList: function ($event) {
                                    _vm.form.enclosureUrl =
                                      $event && $event.length > 0
                                        ? $event.map(function (p) {
                                            return p.url
                                          })
                                        : []
                                    _vm.form.enclosure =
                                      $event && $event.length > 0
                                        ? $event
                                            .map(function (p) {
                                              return p.fileName
                                            })
                                            .join(",")
                                        : ""
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("a-textarea", {
                                attrs: {
                                  placeholder: "请输入备注",
                                  "auto-size": { minRows: 6 },
                                  allowClear: "",
                                },
                                model: {
                                  value: _vm.form.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "remark", $$v)
                                  },
                                  expression: "form.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "footer" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading || _vm.uploading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSave(item, index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.uploading ? "上传中" : "保存")
                              ),
                            ]
                          ),
                          _c(
                            "a-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCancel(item, index)
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }