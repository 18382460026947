<template>
  <vxe-modal
    v-model="open"
    class="labor-contract-modal"
    :title="title"
    width="40%"
    height="auto"
    @close="handleCloseModal"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :position="{ top: '5%', left: '30%' }"
  >
    <div class="header"><a-button type="primary" @click="handleAdd" :disabled="form.isEdit">添加</a-button></div>
    <a-card v-if="items.length === 0" class="item">
      <a-empty />
    </a-card>
    <a-card v-else v-for="(item, index) in items" :key="index" class="item">
      <div class="container" v-if="!item.isEdit">
        <div class="edit-btn">
          <a-button @click="handleEdit(item)" :disabled="form.isEdit">编辑</a-button>
          <a-button @click="handleDelete(item, index)" :disabled="form.isEdit">删除</a-button>
        </div>
        <div class="row">
          <span>签订日期：</span>
          <span class="with-btn">{{ item.signingDate }}</span>
        </div>
        <div class="row">
          <span>合同日期：</span>
          <span>{{ item.contractBeginDate }} 至 {{ item.contractEndDate }}</span>
        </div>
        <div class="row">
          <span>工作职务：</span>
          <span>{{ item.jobTitle }}</span>
        </div>
        <div class="row">
          <span>附件：</span>
          <image-view :image-url="item.enclosureUrl"></image-view>
        </div>
        <div class="row">
          <span>备注：</span>
          <span>{{ item.remark }}</span>
        </div>
      </div>
      <div class="container-edit" v-if="item.isEdit">
        <a-form-model ref="form" :model="form" :rules="rules">
          <a-form-model-item label="签订日期" prop="signingDate">
            <a-date-picker v-model="form.signingDate" value-format="YYYY-MM-DD" format="YYYY-MM-DD" :allowClear="false" />
          </a-form-model-item>
          <a-form-model-item label="开始日期" prop="contractBeginDate">
            <a-date-picker v-model="form.contractBeginDate" value-format="YYYY-MM-DD" format="YYYY-MM-DD" :allowClear="false" />
          </a-form-model-item>
          <a-form-model-item label="结束日期" prop="contractEndDate">
            <a-date-picker v-model="form.contractEndDate" value-format="YYYY-MM-DD" format="YYYY-MM-DD" :allowClear="false" />
          </a-form-model-item>
          <a-form-model-item label="工作职务" prop="jobTitle">
            <a-input v-model="form.jobTitle" placeholder="请输入工作职务" allowClear />
          </a-form-model-item>
          <a-form-model-item label="附件上传">
            <many-file-upload
              :init-file-list="initFileList"
              folder="laborcontract"
              type="image"
              :maxFileCount="10"
              @onUploading="uploading = $event"
              @billImageNum="form.billImageNum = $event"
              @fileList="
                form.enclosureUrl = $event && $event.length > 0 ? $event.map((p) => p.url) : []
                form.enclosure = $event && $event.length > 0 ? $event.map((p) => p.fileName).join(',') : ''
              "
            ></many-file-upload>
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remark">
            <a-textarea v-model="form.remark" placeholder="请输入备注" :auto-size="{ minRows: 6 }" allowClear />
          </a-form-model-item>
        </a-form-model>
        <div class="footer">
          <a-button type="primary" :loading="loading || uploading" @click="handleSave(item, index)"> {{ uploading ? '上传中' : '保存' }}</a-button>
          <a-button @click="handleCancel(item, index)">取消</a-button>
        </div>
      </div>
    </a-card>
  </vxe-modal>
</template>
<script>
import { parseDateTime } from '@/utils/ruoyi'
import ManyFileUpload from '@/components/ManyFileUpload'
import ImageView from '@/components/ImageView'
import { listLaborContract, addLaborContract, updateLaborContract, delLaborContract } from '@/api/iot/laborContract'
import { Empty } from 'ant-design-vue'
export default {
  name: 'LaborContract',
  components: { ManyFileUpload, ImageView, AEmpty: Empty },
  data() {
    return {
      open: false,
      employeeId: null,
      title: null,
      form: {
        signingDate: null,
        contractBeginDate: null,
        contractEndDate: null,
        jobTitle: null,
        enclosure: '',
        enclosureUrl: []
      },
      items: [],
      rules: {
        signingDate: [{ required: true, message: '签订日期不能为空', trigger: 'blur' }],
        contractBeginDate: [{ required: true, message: '合同开始日期不能为空', trigger: 'change' }],
        contractEndDate: [{ required: true, message: '合同结束日期不能为空', trigger: 'change' }],
        jobTitle: [{ required: true, message: '工作职务不能为空', trigger: 'blur' }]
      },
      loading: false,
      uploading: false,
      initFileList: []
    }
  },
  created() {},
  mounted() {
    this.setModalBoxStyle()
  },
  methods: {
    setModalBoxStyle() {
      const e = document.querySelector('.labor-contract-modal .vxe-modal--box')
      if (e) {
        e.style.maxHeight = '90%'
      }
    },
    handleUpdate(row, ids) {
      this.form.isEdit = false
      this.employeeId = row.id
      this.title = row.name + '-劳动合同'
      const queryParam = { employeeId: row.id }
      listLaborContract(queryParam).then((response) => {
        this.items = response.rows
        this.open = true
        if (this.items.length === 0) {
          // this.handleAdd()
        }
      })
    },
    handleCloseModal() {
      this.open = false
    },
    handleEdit(item) {
      item.isEdit = true
      this.form = { ...item }
      setTimeout(() => {
        // 赋初始值
        this.initFileList = { billImage: item.enclosure, billImageUrl: item.enclosureUrl }
      }, 50)
    },
    // 提交前校验
    verifyBeforeSave() {
      console.log('verifyBeforeSave')
      if (this.form.contractEndDate < this.form.contractBeginDate) {
        this.$warningEx('在职结束时间不能小于开始时间！')
        return false
      }
      const { billImageNum, enclosure } = this.form
      const fileNameArr = enclosure ? enclosure.split(',') : []
      if (billImageNum && billImageNum !== fileNameArr.length) {
        this.$warningEx('发起文件上传数量与实际成功上传的数量不一致！')
        return false
      }
      // 判断文件名是否为空
      const n = fileNameArr.length
      for (let k = 0; k < n; k++) {
        if (!fileNameArr[k] || !fileNameArr[k].trim()) {
          this.$warningEx('上传文件名为空！')
          return false
        }
      }
      return true
    },
    handleSave(item, index) {
      if (!this.verifyBeforeSave()) {
        return
      }
      this.$refs.form[0].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.form.id) {
            updateLaborContract(this.form).then((response) => {
              this.loading = false
              this.form.isEdit = false
              item.isEdit = false
              Object.assign(item, response.data)
            })
          } else {
            addLaborContract(this.form).then((response) => {
              this.loading = false
              this.form.isEdit = false
              item.isEdit = false
              Object.assign(item, response.data)
            })
          }
        }
      })
    },
    handleCancel(item, index) {
      // 新记录取消直接删除
      if (!this.items[index].id) {
        this.form.isEdit = false
        this.items.splice(index, 1)
        return
      }
      item.isEdit = false
      this.form.isEdit = false
    },
    handleAdd() {
      const form = {
        employeeId: this.employeeId,
        signingDate: parseDateTime(new Date(), 'yyyy-MM-dd'),
        contractBeginDate: null,
        contractEndDate: null,
        jobTitle: null,
        enclosure: null,
        enclosureUrl: [],
        remark: null,
        isEdit: true
      }
      this.form = { ...form }
      this.items.unshift({ ...this.form })
    },
    handleDelete(item, index) {
      this.$confirm({
        title: '确定要删除这条记录吗？',
        zIndex: 99999,
        onOk: () => {
          delLaborContract(item.id).then((response) => {
            this.items.splice(index, 1)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
<style lang="less" scoped>
.item {
  height: auto !important;
  margin-bottom: 14px;
}
.header {
  height: 44px !important;
}
.container,
.container-edit {
  position: relative;
  display: flex;
  flex-direction: column;
  // border-top: 1px solid #ddd;
  height: auto !important;
  padding: 0;
  .row {
    display: flex;
    padding: 5px 0;
    span:first-child {
      min-width: 70px;
    }
    .with-btn {
      padding-right: 150px;
    }
  }
  .edit-btn {
    position: absolute;
    top: 0;
    right: 0;
    button:first-child {
      margin-right: 5px;
    }
  }
  .ant-form-item {
    margin-bottom: 5px !important;
  }
  .footer {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    button {
      margin-right: 10px;
    }
  }
  .begin-time {
    margin-right: 8px;
  }
  .end-time {
    margin-left: 8px;
  }
}
.container:hover,
.container-edit {
  // border: 1px solid #ff6000 !important;
}
</style>

<style lang="less">
.labor-contract-modal {
  .container-edit {
    .ant-form-item {
      display: flex;
      .ant-form-item-label {
        flex: 0 0 80px;
      }
      .ant-form-item-control-wrapper {
        flex: 1;
      }
    }
    .ant-form-item-children {
      display: flex;
      align-items: center;
    }
  }
  .vxe-modal--body {
    min-height: 608px;
  }
  .vxe-modal--content {
    padding-bottom: 0;
  }
  .ant-empty-image {
    height: 459px;
  }
}
</style>
