import request from '@/utils/request'

// 查询劳动合同列表
export function listLaborContract(query) {
  return request({
    url: '/iot/laborContract/list',
    method: 'get',
    params: query
  })
}

// 获取劳动合同详细信息
export function getLaborContract(id) {
  return request({
    url: '/iot/laborContract/' + id,
    method: 'get'
  })
}

// 新增劳动合同
export function addLaborContract(data) {
  return request({
    url: '/iot/laborContract',
    method: 'post',
    data: data
  })
}

// 修改劳动合同
export function updateLaborContract(data) {
  return request({
    url: '/iot/laborContract',
    method: 'put',
    data: data
  })
}

// 删除劳动合同
export function delLaborContract(id) {
  return request({
    url: '/iot/laborContract/' + id,
    method: 'delete'
  })
}
