var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "viewer",
    { staticClass: "image-view", attrs: { images: _vm.fileList } },
    _vm._l(_vm.fileList, function (item) {
      return _c("div", { key: item, staticClass: "image-view-item" }, [
        item && item.indexOf(".pdf") > 0
          ? _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handlePdfPreview(item)
                  },
                },
              },
              [_vm._v("PDF文档")]
            )
          : item && (item.indexOf(".xls") > 0 || item.indexOf(".xlsx") > 0)
          ? _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleOfficePreview(item)
                  },
                },
              },
              [_vm._v("Excel文档")]
            )
          : _c("img", { attrs: { src: item, height: "100" } }),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }